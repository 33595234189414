import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import moment from 'moment';
import {
  ButtonBase,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import i18next from 'i18next';
import { useEventListener } from '../../Hooks';
import { getDataFromObject } from '../../Helpers';
import { Inputs, PopoverComponent } from 'Components';
import { PaginationComponent } from '../Pagination/Pagination.Component';
import { LoaderComponent } from '../Loader/Loader.Component';
import { CheckboxesComponent } from '../Checkboxes/Checkboxes.Component';
import './Tables.Style.scss';
import { classNames } from 'utils/utils';

export const TablesComponent = memo(
  ({
    isLocalFilter,
    selectedFilters,
    filterObject,
    setSelectedFilters,
    tableRowValidationArray,
    tableOptions,
    data,
    headerData,
    footerData,
    sortColumnClicked,
    focusedRowChanged,
    dateFormat,
    headerRowRef,
    bodyRowRef,
    onHeaderColumnsReorder,
    isSelectAllDisabled,
    uniqueKeyInput,
    isWithCheckAll,
    isWithCheck,
    onSelectAllCheckboxChanged,
    onSelectCheckboxChanged,
    getIsSelectedRow,
    getIsDisabledRow,
    isSelectAll,
    isStickyCheckboxColumn,
    leftCheckboxColumn,
    rightCheckboxColumn,
    selectedRows,
    onSelectedRowsCountChanged,
    isResizable,
    isLoading,
    tableActions,
    isWithTableActions,
    tableActionsOptions,
    isDisabledActions,
    onActionClicked,
    isResizeCheckboxColumn,
    isOriginalPagination,
    paginationIdRef,
    onPageIndexChanged,
    totalItems,
    pageIndex,
    pageSize,
    onPageSizeChanged,
    onTableRowClicked,
    tableActionText,
    disabledRow,
    isOdd,
  }) => {
    const [reorderedHeader, setReorderedHeader] = useState(null);
    const [currentDraggingColumn, setCurrentDraggingColumn] = useState(null);
    const [currentDragOverIndex, setCurrentDragOverIndex] = useState(null);
    const currentResizingColumnRef = useRef(null);
    const startResizePointRef = useRef(null);
    const [localSelectedRows, setLocalSelectedRows] = useState([]);
    const [currentOrderById, setCurrentOrderById] = useState(-1);
    const [, setActiveItem] = useState(null);
    const [currentOrderDirection, setCurrentOrderDirection] = useState('desc');
    const tableRef = useRef(null);
    const [focusedRow, setFocusedRow] = useState(-1);
    const [popoverFilterAttachedWith, setPopoverFilterAttachedWith] = useState(null);
    const [selectedFilterButtonKey, setSelectedFilterButtonKey] = useState('');
    const [filterSearchValue, setFilterSearchValue] = useState('');

    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;

      return 0;
    };

    const getComparator = (order, orderBy) =>
      order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    const createSortHandler = useCallback(
      (columnId) => () => {
        if (!tableOptions) return;
        setCurrentOrderDirection((item) => (item === 'desc' ? 'asc' : 'desc'));
        setCurrentOrderById(columnId);
        if (tableOptions.sortFrom === 2) sortColumnClicked(columnId, currentOrderDirection);
      },
      [currentOrderDirection, tableOptions, sortColumnClicked],
    );
    const stableSort = (array, comparator) => {
      const stabilizedThis =
        (array &&
          array.length > 0 &&
          Array.isArray(array) &&
          array.map((el, index) => [el, index])) ||
        [];

      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;

        return a[1] - b[1];
      });

      return stabilizedThis.map((el) => el[0]);
    };
    const getCurrentSelectedItemIndex = useCallback(
      (row) =>
        localSelectedRows.findIndex(
          (item) =>
            getDataFromObject(row, uniqueKeyInput) === getDataFromObject(item, uniqueKeyInput),
        ),
      [localSelectedRows, uniqueKeyInput],
    );
    const onSelectAllCheckboxChangedHandler = useCallback(
      (event) => {
        const isChecked = event.target.checked;
        if (!selectedRows)
          if (isChecked) setLocalSelectedRows([...data]);
          else setLocalSelectedRows([]);

        if (onSelectAllCheckboxChanged)
          onSelectAllCheckboxChanged({
            selectedRows: selectedRows || data,
            isChecked,
          });
      },
      [data, onSelectAllCheckboxChanged, selectedRows],
    );
    const onSelectCheckboxChangedHandler = useCallback(
      (row, rowIndex) => (event) => {
        event.stopPropagation();
        const isChecked = event.target.checked;
        if (!selectedRows)
          setLocalSelectedRows((items) => {
            const localRowIndex = getCurrentSelectedItemIndex(row);
            if (isChecked) items.push(row);
            else if (localRowIndex !== -1) items.splice(localRowIndex, 1);

            if (onSelectCheckboxChanged)
              onSelectCheckboxChanged({
                selectedRows: items,
                selectedRow: row,
                rowIndex,
              });

            return [...items];
          });
        else if (onSelectCheckboxChanged) onSelectCheckboxChanged({ selectedRow: row, rowIndex });
      },
      [getCurrentSelectedItemIndex, onSelectCheckboxChanged, selectedRows],
    );
    const bodyRowClicked = useCallback(
      (rowIndex, item) => {
        setActiveItem(item);
        if (focusedRow === -1 || focusedRow !== rowIndex)
          setFocusedRow(() => {
            focusedRowChanged(rowIndex, item);

            return rowIndex;
          });
        else
          setFocusedRow(() => {
            focusedRowChanged(-1);

            return -1;
          });
      },
      [focusedRow, focusedRowChanged],
    );
    // const getTableActionValue = (key) =>
    //   Object.values(TableActions).find((item) => item.key === key);
    const getSortDataName = () => {
      const currentHeader = (reorderedHeader || headerData).find(
        (item) => item.id === currentOrderById,
      );
      if (currentHeader) return currentHeader.input;

      return null;
    };
    const getStickyStyle = useCallback(
      (item) => ({
        position: window.screen.width >= 550 ? 'sticky' : 'initial',
        left:
          i18next.dir() === 'ltr'
            ? ((item.left || item.left === 0) && item.left) || 'initial'
            : ((item.right || item.right === 0) && item.right) || 'initial',
        right:
          i18next.dir() === 'ltr'
            ? ((item.right || item.right === 0) && item.right) || 'initial'
            : ((item.left || item.left === 0) && item.left) || 'initial',
        zIndex: 1,
      }),
      [],
    );
    const onDragColumnHandler = useCallback(
      (index) => (event) => {
        event.dataTransfer.setData('text', event.currentTarget.id);
        setCurrentDraggingColumn(index);
      },
      [],
    );
    const onDragEndColumnHandler = useCallback(() => {
      if (currentDragOverIndex !== null) setCurrentDragOverIndex(null);
    }, [currentDragOverIndex]);
    const onDragOverColumnHandler = useCallback(
      (index) => (event) => {
        event.preventDefault();
        if (currentDragOverIndex !== index) setCurrentDragOverIndex(index);
      },
      [currentDragOverIndex],
    );
    const onDropColumnHandler = useCallback(
      (index) => (event) => {
        event.preventDefault();
        if (!currentDraggingColumn && currentDraggingColumn !== 0) return;

        const localColumns = [...(reorderedHeader || headerData)];
        localColumns.splice(index, 0, localColumns.splice(currentDraggingColumn, 1)[0]);
        if (onHeaderColumnsReorder) onHeaderColumnsReorder(localColumns);
        else setReorderedHeader(localColumns);
      },
      [currentDraggingColumn, headerData, onHeaderColumnsReorder, reorderedHeader],
    );
    const onResizeDownHandler = useCallback(
      (idRef) => (event) => {
        event.preventDefault();
        if (!idRef) return;
        currentResizingColumnRef.current = document.querySelector(idRef);
        startResizePointRef.current = currentResizingColumnRef.current.offsetWidth - event.pageX;
      },
      [],
    );
    const onResizeMoveHandler = useCallback((event) => {
      if (!currentResizingColumnRef.current || startResizePointRef.current === null) return;
      currentResizingColumnRef.current.style.width = `${
        startResizePointRef.current + event.pageX
      }px`;
    }, []);
    const onResizeUpHandler = useCallback(() => {
      currentResizingColumnRef.current = null;
      currentResizingColumnRef.current = null;
    }, []);
    useEventListener('mousemove', onResizeMoveHandler);
    useEventListener('mouseup', onResizeUpHandler);

    const onActionClickedHandler = useCallback(
      (row, rowIndex) => (event) => {
        if (onActionClicked) onActionClicked(row, rowIndex, event);
      },
      [onActionClicked],
    );

    const onTableRowClickedHandler = useCallback(
      (row) => {
        if (onTableRowClicked) onTableRowClicked(row);
      },
      [onTableRowClicked],
    );

    useEffect(() => {
      if ((selectedRows || localSelectedRows) && onSelectedRowsCountChanged)
        onSelectedRowsCountChanged((selectedRows || localSelectedRows).length);
    }, [localSelectedRows, onSelectedRowsCountChanged, selectedRows]);
    useEffect(() => {
      if (selectedRows) setLocalSelectedRows(selectedRows);
    }, [selectedRows]);

    return (
      <div className='w-100 table-responsive' ref={tableRef}>
        <TableContainer>
          <Table
            className='table-wrapper'
            aria-labelledby='tableTitle'
            size={tableOptions.tableSize} // 'small' or 'medium'
            aria-label='enhanced table'
          >
            <TableHead>
              <TableRow>
                {isWithCheckAll && (
                  <TableCell
                    padding='checkbox'
                    className='checkboxes-cell'
                    style={
                      (isStickyCheckboxColumn &&
                        getStickyStyle({
                          right: rightCheckboxColumn,
                          left: leftCheckboxColumn,
                        })) ||
                      undefined
                    }
                    id={`${headerRowRef}checkAllColumnId`}
                  >
                    <CheckboxesComponent
                      idRef={`${headerRowRef}tableSelectAllRef`}
                      singleIndeterminate={
                        localSelectedRows &&
                        localSelectedRows.length > 0 &&
                        localSelectedRows.length < totalItems &&
                        !isSelectAll
                      }
                      singleChecked={
                        isSelectAll ||
                        (totalItems > 0 &&
                          localSelectedRows &&
                          localSelectedRows.length === totalItems)
                      }
                      isDisabled={isSelectAllDisabled}
                      onSelectedCheckboxChanged={onSelectAllCheckboxChangedHandler}
                    />
                    {(isResizeCheckboxColumn || isResizable) && (
                      <ButtonBase
                        className='resize-btn'
                        onMouseDown={onResizeDownHandler(`#${headerRowRef}checkAllColumnId`)}
                      >
                        <span />
                      </ButtonBase>
                    )}
                  </TableCell>
                )}
                {(reorderedHeader || headerData)
                  .filter((column) => !column.isHidden)
                  .map((item, index) => (
                    <TableCell
                      sortDirection={
                        !item.isSortable && currentOrderById === item.id
                          ? currentOrderDirection
                          : false
                      }
                      draggable={item?.isDraggable}
                      id={`${headerRowRef}${index + 1}`}
                      onDragEnd={onDragEndColumnHandler}
                      key={`${headerRowRef}${index + 1}`}
                      onDrag={onDragColumnHandler(index)}
                      onDrop={onDropColumnHandler(index)}
                      onDragOver={onDragOverColumnHandler(index)}
                      style={(item.isSticky && getStickyStyle(item)) || undefined}
                      className={classNames({
                        'drag-over-cell': index === currentDragOverIndex,
                        [item.className]: item.className,
                      })}
                    >
                      {!item.isSortable && (
                        <TableSortLabel
                          onClick={createSortHandler(item.id)}
                          active={currentOrderById === item.id}
                          onMouseDown={onResizeDownHandler(`#${headerRowRef}${index + 1}`)}
                          IconComponent={() => <span className='mdi mdi-menu-swap c-gray' />}
                          direction={currentOrderById === item.id ? currentOrderDirection : 'desc'}
                        >
                          {(item.headerComponent && item.headerComponent(item, index)) ||
                            item.label}
                        </TableSortLabel>
                      )}

                      {item.isFilter && (
                        <ButtonBase
                          className={`btns theme-short filter-btn ${
                            selectedFilters &&
                            selectedFilterButtonKey &&
                            selectedFilters[item.input].length > 0
                              ? 'is-active'
                              : ''
                          }`}
                          onClick={(event) => {
                            setFilterSearchValue('');
                            setSelectedFilterButtonKey(item.input);
                            setPopoverFilterAttachedWith(event.target);
                          }}
                        >
                          {(item.headerComponent && item.headerComponent(item, index)) ||
                            item.label}

                          {selectedFilters &&
                          selectedFilterButtonKey &&
                          selectedFilters[item.input].length > 0 ? (
                            <span className='selected-filter-amount'>
                              {selectedFilters[item.input].length}
                            </span>
                          ) : (
                            ''
                          )}

                          <span className='mdi mdi-filter-variant ml-2' />
                        </ButtonBase>
                      )}

                      {!item.isFilter &&
                        item.isSortable &&
                        ((item.headerComponent && item.headerComponent(item, index)) || item.label)}
                    </TableCell>
                  ))}

                <PopoverComponent
                  idRef='tableFilterPopoverIdRef'
                  attachedWith={popoverFilterAttachedWith}
                  handleClose={() => setPopoverFilterAttachedWith(null)}
                  component={
                    <div className='filters-table-popover-wrapper'>
                      <div className='filter-body'>
                        <List>
                          <Inputs
                            themeClass='theme-gray'
                            inputPlaceholder='Search'
                            idRef='tableInputInputRef'
                            value={filterSearchValue || ''}
                            wrapperClasses='filter-search-wrapper'
                            startAdornment={
                              selectedFilterButtonKey &&
                              selectedFilters &&
                              selectedFilters[selectedFilterButtonKey] &&
                              selectedFilters[selectedFilterButtonKey].map((item, index) => (
                                <Chip
                                  label={`${item}`}
                                  key={`${index + 1}-selected-filter-chip`}
                                  deleteIcon={<span className='mdi mdi-close c-white' />}
                                  onDelete={() =>
                                    setSelectedFilters((items) => {
                                      const localIndex = items[selectedFilterButtonKey].findIndex(
                                        (el) => el === item,
                                      );

                                      items[selectedFilterButtonKey].splice(localIndex, 1);

                                      return { ...items };
                                    })
                                  }
                                />
                              ))
                            }
                            onInputChanged={(event) => {
                              const { value } = event.target;

                              setFilterSearchValue(value);
                            }}
                          />

                          <div className='filter-values-wrapper'>
                            {filterObject &&
                              selectedFilterButtonKey &&
                              filterObject[selectedFilterButtonKey] &&
                              filterObject[selectedFilterButtonKey]
                                .filter(
                                  (item) =>
                                    item &&
                                    item.toLowerCase().includes(filterSearchValue.toLowerCase()),
                                )
                                .map(
                                  (value, index) =>
                                    value && (
                                      <ListItem
                                        key={`${index + 1}-blend-filter-item`}
                                        disablePadding
                                      >
                                        <ListItemButton
                                          dense
                                          role={undefined}
                                          onClick={() => {
                                            setSelectedFilters((items) => {
                                              const localIndex = items[
                                                selectedFilterButtonKey
                                              ].findIndex((item) => item === value);

                                              if (localIndex === -1)
                                                items[selectedFilterButtonKey].push(value);
                                              else
                                                items[selectedFilterButtonKey].splice(
                                                  localIndex,
                                                  1,
                                                );

                                              return { ...items };
                                            });
                                          }}
                                        >
                                          <ListItemIcon>
                                            <CheckboxesComponent
                                              disableRipple
                                              singleChecked={
                                                selectedFilters &&
                                                selectedFilters[selectedFilterButtonKey].findIndex(
                                                  (item) => item === value,
                                                ) !== -1
                                              }
                                              idRef={`${value}-blend-checkbox-${index + 1}`}
                                            />
                                          </ListItemIcon>
                                          <ListItemText id={`${value}`} primary={`${value}`} />
                                        </ListItemButton>
                                      </ListItem>
                                    ),
                                )}
                          </div>
                        </List>
                      </div>
                    </div>
                  }
                />

                {isWithTableActions && tableActionsOptions && (
                  <TableCell
                    id={`${headerRowRef}tableActionsOptionsRef`}
                    className='actions-cells'
                    style={
                      (tableActionsOptions.isSticky && getStickyStyle(tableActionsOptions)) ||
                      undefined
                    }
                  >
                    <div className='actions-cell'>
                      <div className='actions-cell-item'>{tableActionText}</div>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            {!isLoading && (
              <TableBody className={`${isOdd ? 'is-odd' : ''}`}>
                {stableSort(data, getComparator(currentOrderDirection, getSortDataName())).map(
                  (row, rowIndex) => {
                    const isItemSelected = getCurrentSelectedItemIndex(row) !== -1;
                    return (
                      <React.Fragment key={`bodyRow${rowIndex * (pageIndex + 1)}`}>
                        <TableRow
                          role='checkbox'
                          aria-checked={
                            (getIsSelectedRow && getIsSelectedRow(row, rowIndex)) || isItemSelected
                          }
                          tabIndex={-1}
                          selected={
                            (getIsSelectedRow && getIsSelectedRow(row, rowIndex)) || isItemSelected
                          }
                          id={`${bodyRowRef}${rowIndex * (pageIndex + 1)}`}
                          onClick={(event) => {
                            event.stopPropagation();
                            bodyRowClicked(rowIndex, row);
                            onTableRowClickedHandler(row);
                          }}
                          className={`${
                            rowIndex === focusedRow || row?.is_active ? 'table-row-overlay' : ''
                          } ${
                            tableRowValidationArray.findIndex(
                              (item) =>
                                item === row.product_id || item === row.id || item === row.user_id,
                            ) !== -1
                              ? 'has-validation'
                              : ''
                          } ${disabledRow && disabledRow(row) ? 'is-disabled' : ''}`}
                        >
                          {(isWithCheck || getIsSelectedRow || selectedRows) && (
                            <TableCell
                              padding='checkbox'
                              style={(row.isSticky && getStickyStyle(row)) || undefined}
                            >
                              <CheckboxesComponent
                                idRef={`tableSelectRef${rowIndex + 1}`}
                                singleChecked={
                                  isSelectAll ||
                                  (getIsSelectedRow && getIsSelectedRow(row, rowIndex)) ||
                                  isItemSelected ||
                                  false
                                }
                                isDisabled={
                                  isSelectAllDisabled ||
                                  (getIsDisabledRow && getIsDisabledRow(row, rowIndex))
                                }
                                onSelectedCheckboxChanged={onSelectCheckboxChangedHandler(
                                  row,
                                  rowIndex,
                                )}
                              />
                              <div />
                            </TableCell>
                          )}
                          {headerData.length > 0 &&
                            (reorderedHeader || headerData)
                              .filter((column) => !column.isHidden)
                              .map((column, columnIndex) => (
                                <TableCell
                                  key={`bodyColumn${columnIndex * (pageIndex + 1) + rowIndex}`}
                                  className={column.cellClasses || ''}
                                  style={(column.isSticky && getStickyStyle(column)) || undefined}
                                >
                                  {(column.isDate &&
                                    ((getDataFromObject(row, column.input) &&
                                      moment
                                        .utc(getDataFromObject(row, column.input))
                                        .local()
                                        .format(
                                          column.dateFormat ||
                                            tableOptions.dateFormat ||
                                            dateFormat,
                                        )) ||
                                      '')) ||
                                    (column.component &&
                                      column.component(row, rowIndex, column, columnIndex)) ||
                                    getDataFromObject(row, column.input)}
                                </TableCell>
                              ))}
                          {isWithTableActions && tableActionsOptions && (
                            <TableCell
                              key={`bodyActionsColumn${rowIndex + 1}`}
                              className={`actions-cell-wrapper ${
                                tableActionsOptions.cellClasses || ''
                              }`}
                              style={
                                (tableActionsOptions.isSticky &&
                                  getStickyStyle(tableActionsOptions)) ||
                                undefined
                              }
                            >
                              {(tableActionsOptions.component &&
                                tableActionsOptions.component(row, rowIndex)) ||
                                (tableActions &&
                                  tableActions.map((item) => (
                                    <ButtonBase
                                      disabled={
                                        (tableActionsOptions &&
                                          tableActionsOptions.getDisabledAction &&
                                          tableActionsOptions.getDisabledAction(
                                            row,
                                            rowIndex,
                                            item,
                                          )) ||
                                        isDisabledActions
                                      }
                                      onClick={onActionClickedHandler(item, row, rowIndex)}
                                      key={`${item.key}-${rowIndex + 1}`}
                                      className={`btns mx-1 theme-solid ${item.bgColor || ''}`}
                                    >
                                      <span className={item.icon} />
                                      {item.value}
                                    </ButtonBase>
                                  ))) ||
                                null}
                            </TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    );
                  },
                )}
              </TableBody>
            )}

            {footerData && footerData.length > 0 && (
              <TableFooter className='footer-wrapper'>
                <TableRow>
                  {footerData.map((item, index) => (
                    <TableCell colSpan={item.colSpan} key={`footerCell${index + 1}`}>
                      {(item.component && item.component(item, index)) || item.value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>

        <LoaderComponent
          isLoading={isLoading}
          isSkeleton
          wrapperClasses='table-loader-wrapper'
          skeletonItems={[{ varient: 'rectangular', className: 'table-loader-row' }]}
          numberOfRepeat={(totalItems / pageIndex > pageSize && pageSize) || 6}
        />

        {!isOriginalPagination && (onPageIndexChanged || onPageSizeChanged) && (
          <PaginationComponent
            idRef={paginationIdRef}
            totalCount={totalItems}
            pageIndex={pageIndex}
            pageSize={pageSize}
            isButtonsNavigation
            isReversedSections
            isRemoveTexts
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
          />
        )}
      </div>
    );
  },
);

TablesComponent.defaultProps = {
  // checkboxes related features
  selectedRows: undefined,
  uniqueKeyInput: undefined,
  onSelectAllCheckboxChanged: undefined,
  onSelectCheckboxChanged: undefined,
  onSelectedRowsCountChanged: undefined,
  getIsSelectedRow: undefined,
  getIsDisabledRow: undefined,
  tableRowValidationArray: [],
  isResizable: false,
  isWithCheckAll: false,
  isWithCheck: false,
  isSelectAll: false,
  isResizeCheckboxColumn: false,
  isStickyCheckboxColumn: true,
  leftCheckboxColumn: undefined,
  rightCheckboxColumn: undefined,
  // start table actions
  tableActions: undefined,
  isWithTableActions: false,
  tableActionsOptions: { isSticky: true, right: '-1px' },
  onActionClicked: undefined,
  isDisabledActions: undefined,
  // end table actions
  // end checkboxes related features
  filterObject: {},
  selectedFilters: {},
  isSelectAllDisabled: false,
  dateFormat: 'ddd, MMM DD, h:mm A',
  tableActionText: 'Actions',
  tableOptions: {
    pageSizeOptions: [10, 20, 25, 50, 100],
    tableSize: 'medium',
    dateFormat: null,
    sortFrom: 1, // 1:front,2:do nothing only send that it change
  },
  parentTranslationPath: '',
  translationPath: '',
  sortColumnClicked: () => {},
  setSelectedFilters: () => {},
  onTableRowClicked: () => {},
  disabledRow: () => {},
  headerData: [],
  data: [],
  footerData: [],
  onHeaderColumnsReorder: undefined,
  focusedRowChanged: () => {},
  headerRowRef: 'headerRowRef',
  bodyRowRef: 'bodyRowRef',
  // pagination
  paginationIdRef: undefined,
  onPageIndexChanged: undefined,
  onPageSizeChanged: undefined,
  isOriginalPagination: false,
  isLoading: false,
  pageSize: 10,
  pageIndex: 0,
  isOdd: false,
  isLocalFilter: false,
};
