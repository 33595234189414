export const SchemaEntitiesTypeEnum = [
  { key: 'str', value: 'String' },
  { key: 'int', value: 'Integer' },
  { key: 'float', value: 'Float' },
  { key: 'bool', value: 'Boolean' },
  { key: 'None', value: 'None' },
];

export const SchemaEntitiesObjectTypeEnum = {
  str: 'String',
  int: 'Integer',
  float: 'Float',
  bool: 'Boolean',
  None: 'None',
};

export const schemaEntitiesType = {
  string: 'str',
  integer: 'int',
  float: 'float',
  boolean: 'bool',
  none: 'None',
};

export const booleanEnum = [
  { key: 'true', value: 'True' },
  { key: 'false', value: 'False' },
];
