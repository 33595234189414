import loadable from '@loadable/component';

const ProductDataUploadView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductDataUploadView/ProductDataUploadView'
  ),
);
const ProductEstimatorView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductEstimatorView/ProductEstimatorView'
  ),
);
const ProductSimulatorView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductSimulatorView/ProductSimulatorView'
  ),
);
const ProductSimulatorManagementView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductSimulatorView/ProductSimulatorManagementView'
  ),
);
const ProductSimulatorBlendView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductSimulatorView/ProductSimulatorBlendView'
  ),
);
const ProductOptimizerView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductOptimizerView/ProductOptimizerView'
  ),
);
const ProductOptimizerManagementView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductOptimizerView/ProductOptimizerManagementView'
  ),
);
const ProductOptimizerResultView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductOptimizerView/ProductOptimizerResultView'
  ),
);
const ProductEDAView = loadable(() =>
  import('../../../Views/Home/products-page/product-views/ProductEDAView/ProductEDAView'),
);
const ProductEDAManagementView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductEDAView/ProductEDAManagementView.jsx'
  ),
);
const ProductEDAAnalyticsView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductEDAView/ProductEDAAnalyticsView.jsx'
  ),
);
const ProductEstimatorsView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductEstimatorsView/ProductEstimatorsView.jsx'
  ),
);
const ProductEstimatorManagementView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductEstimatorsView/ProductEstimatorManagementView.jsx'
  ),
);
const ProductEstimatorBuilderSteps = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductEstimatorBuilder/ProductEstimatorBuilderSteps.jsx'
  ),
);
const ProductEstimatorResultsView = loadable(() =>
  import(
    '../../../Views/Home/products-page/product-views/ProductEstimatorsView/ProductEstimatorResultsView.jsx'
  ),
);

const ProductRoutes = [
  {
    id: 1,
    path: '/product-data-upload',
    name: 'Data Upload',
    translationSectionKeys: 'side_menu',
    translationKeys: 'data_upload',
    component: ProductDataUploadView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-archive-outline',
    isDisabled: false,
    isHidden: false,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'datasets',
    permissionKey: 'data_upload',
  },
  {
    id: 10,
    path: '/product-eda',
    name: 'EDA',
    component: ProductEDAView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-chart-pie-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: false,
  },
  {
    id: 2,
    path: '/product-estimator',
    name: 'Predictive Modeling',
    translationSectionKeys: 'side_menu',
    translationKeys: 'predictive_modeling',
    component: ProductEstimatorView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-file-chart-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'estimator',
    permissionKey: 'predictive_modeling',
  },
  {
    id: 14,
    path: '/product-estimators',
    name: 'Predictive Modeling',
    translationSectionKeys: 'side_menu',
    translationKeys: 'predictive_modeling',
    component: ProductEstimatorsView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-file-chart-outline',
    isDisabled: false,
    isHidden: false,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'estimator',
    permissionKey: 'predictive_modeling',
  },
  {
    id: 15,
    path: '/product-estimators/product-estimator-management',
    name: 'Predictive Modeling',
    translationSectionKeys: 'side_menu',
    translationKeys: 'predictive_modeling',
    component: ProductEstimatorManagementView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-file-chart-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'estimator',
    permissionKey: 'predictive_modeling',
  },
  {
    id: 16,
    path: '/product-estimators/product-estimators-results',
    name: 'Predictive Modeling',
    translationSectionKeys: 'side_menu',
    translationKeys: 'predictive_modeling',
    component: ProductEstimatorResultsView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-file-chart-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'estimator',
    permissionKey: 'predictive_modeling',
  },
  {
    id: 3,
    path: '/product-simulator',
    name: 'Quality Prediction',
    translationSectionKeys: 'side_menu',
    translationKeys: 'quality_prediction',
    component: ProductSimulatorView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-cube-scan',
    isDisabled: false,
    isHidden: false,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'experiments',
    permissionKey: 'quality_prediction',
  },
  {
    id: 4,
    path: '/product-optimizer',
    name: 'Blend Generator',
    translationSectionKeys: 'side_menu',
    translationKeys: 'blend_generator',
    component: ProductOptimizerView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-network-pos',
    isDisabled: false,
    isHidden: false,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'optimizer',
    permissionKey: 'blend_generator',
  },
  {
    id: 5,
    path: '/product-simulator/product-simulator-management',
    name: 'Quality Prediction Management',
    component: ProductSimulatorManagementView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-cube-scan',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'experiments',
  },
  {
    id: 6,
    path: '/product-simulator/product-simulator-blend',
    name: 'Quality Prediction Blend',
    component: ProductSimulatorBlendView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-cube-scan',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'experiments',
  },
  {
    id: 8,
    path: '/product-optimizer/product-optimizer-management',
    name: 'Blend Generator Management',
    component: ProductOptimizerManagementView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-cube-scan',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'optimizer',
  },
  {
    id: 9,
    path: '/product-optimizer/product-optimizer-result',
    name: 'Blend Generator Result',
    component: ProductOptimizerResultView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-cube-scan',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'optimizer',
  },
  {
    id: 11,
    path: '/product-eda/product-eda-management',
    name: 'EDA',
    component: ProductEDAManagementView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-chart-pie-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: false,
  },
  {
    id: 12,
    path: '/product-eda/product-eda-analytics/:productId',
    name: 'EDAAnalytics',
    component: ProductEDAAnalyticsView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-chart-pie-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: false,
  },
  {
    id: 13,
    path: '/product-eda/product-eda-analytics/:productId/:section',
    name: 'EDAAnalytics',
    component: ProductEDAAnalyticsView,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-chart-pie-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: false,
  },
  {
    id: 18,
    path: '/product-estimators/product-estimator-management/estimator-builder',
    name: 'Predictive Modeling',
    component: ProductEstimatorBuilderSteps,
    layout: '/products',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    icon: 'mdi mdi-file-chart-outline',
    isDisabled: false,
    isHidden: true,
    isRecursive: false,
    isExact: true,
    children: [],
    authorizedKey: 'estimator',
  },
];

export default ProductRoutes;
